import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

const mySwiper = new Swiper(".swiper-container", {
  loop: true,

  pagination: {
    clickable: true,
    el: ".swiper-pagination",
  },
});

const DEFAULT_PRODUCT_ID = 1;

const mapSearchQueryToProductId = {
  mh700: 1,
  orlik: 2,
};

const mapProductIdToSearchQuery = {
  1: "mh700",
  2: "orlik",
};

const setProductToSearchUrl = (id) => {
  if (!("URLSearchParams" in window)) return;

  var searchParams = new URLSearchParams(window.location.search);
  const product = mapProductIdToSearchQuery[id];
  searchParams.set("product", product);
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
};

const getProductFromSearchUrl = (id) => {
  if ("URLSearchParams" in window) {
    var searchParams = new URLSearchParams(window.location.search);
    return mapSearchQueryToProductId[searchParams.get("product")];
  }
};

const variants = document.querySelectorAll(".product_variant");
const abouts = document.querySelectorAll(".product_about");
const slides = document.querySelectorAll(".product-slides");

const setProduct = (id) => {
  variants.forEach((el) => {
    if (+el.dataset.id === id) {
      el.classList.add("product_variant--active");
      return;
    }
    el.classList.remove("product_variant--active");
  });

  abouts.forEach((el) => {
    if (+el.dataset.id === id) {
      el.classList.add("product_about--active");
      return;
    }
    el.classList.remove("product_about--active");
  });

  slides.forEach((el) => {
    if (+el.dataset.id !== id) return;
    const clone = document.importNode(el.content, true);

    mySwiper.removeAllSlides();
    mySwiper.appendSlide(clone);
    mySwiper.slideTo(1);
  });
};

let activeProductId = DEFAULT_PRODUCT_ID;

variants.forEach((element) => {
  element.addEventListener("click", (evt) => {
    const { id } = evt.currentTarget.dataset;
    if (!id) return;
    if (activeProductId === id) return;
    activeProductId = id;

    setProductToSearchUrl(id);
    setProduct(+id);
  });
});

const initProduct = () => {
  const productId = getProductFromSearchUrl();
  setProduct(productId || DEFAULT_PRODUCT_ID);
};

initProduct();

document.querySelectorAll(".product_order-btn").forEach((el) => {
  el.addEventListener("click", (evt) => {
    const modal = document.querySelector(".modal");
    const modalClose = document.querySelector(".modal_close");
    const copyBtn = document.querySelector(".copy-btn");

    modal.classList.add("modal--open");

    modal.addEventListener("click", (evt) => {
      if (evt.target !== modal) return;
      modal.classList.remove("modal--open");
    });
    modalClose.addEventListener("click", () => {
      modal.classList.remove("modal--open");
    });

    copyBtn.addEventListener("click", () => {
      const str = document.getElementById("js-copy-content").innerText;

      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      copyBtn.innerText = "скопировано!";
      copyBtn.style.color = "green";
      copyBtn.disabled = true;

      setTimeout(() => {
        copyBtn.innerText = "скопировать";
        copyBtn.style = {};
        copyBtn.disabled = false;
      }, 500);
    });
  });
});
